// ==========================================================================
// Card
// ==========================================================================

//== Import global variables and utilities
@import 'theme';

//== Component Specific Styles

.card {
  background-color: #fff;
  padding: 16px 24px;
  border-radius: 6px;
  margin-bottom: 24px;
  color: $gray;
  transition: 200ms ease-out;
  &.link {
    &:hover,
    &:active {
      text-decoration: none;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
    }
  }
  &.large {
    padding: 24px 32px 36px;
  }
  &.loading {
    background: linear-gradient(
      90deg,
      #eeeeee,
      #eeeeee 25%,
      #f3f3f3,
      #eeeeee 75%,
      #eeeeee
    );
    background-size: 200% 200%;
    background-position-x: 100%;
    animation: Animation 3s ease-out infinite;
    animation-direction: normal;
    animation-delay: 0.5s;
  }
}

@keyframes Animation {
  0% {
    background-position-x: 100%;
  }
  50% {
    background-position: 0%;
  }
  100% {
    background-position-x: 100%;
  }
}
