// ==========================================================================
// Login Form
// ==========================================================================

//== Import global variables and utilities
@import 'theme';

//== Component Specific Styles

.c {
  background-color: #fff;
  border-radius: 2px;
  padding: 28px 44px 64px;
}

.frequent-app-title {
  display: flex;
  flex: 1;
}

.frequent-apps {
  display: flex;
  justify-content: space-around;
  margin-bottom: 80px;
  div {
    margin: 8px 0px;
  }
}