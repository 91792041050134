//== Import global variables and utilities
@import 'theme';

//== Component Specific Styles
$border-color: #c1c1c3;
$text-link-color: #0f69af;
$text-color: #2e2d33;
$star-filled: #ffd35b;
$break-small: 576px;


:global {
  .ant-alert-warning .ant-alert-icon {
    all: unset;
    color: #ce0000;
    position: relative;
  }

  .ant-alert-close-icon {
    top: unset;
    i.anticon-cross {
      color: #0f69af;
    }
    
  }

  .ant-alert-message {
    font-family: Lato;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    margin-left: 10px;
  }
}
.alert-error-warning {
  height: 56px;
  margin: 32px 80px 0;
  padding: 0 16px 0 16px;
  border-radius: 5px;
  border: solid 2px #ce0000;
  background-color: #fff;
  display: flex;
  justify-content: left;
  align-items: center;

 
}

.alert-container {
  padding: 30px 120px;
  margin: 0;
  display: flex;
  justify-content: center;
  .ant-alert-info-custom {
    border: 1px solid #f7f7f7;
    background-color: #f7f7f7;
  }

 
}
.all-apps-container,
.quick-access-container {
  padding: 30px 120px;
  margin: 0;

  @media screen and (max-width: $break-small) {
    padding: 30px;
    margin: 0;
  }
}

.header {
  padding: 0;
  h3 {
    font-size: 18px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
  }
}

.app-box {
  display: flex;
  border: thin solid $border-color;
  border-radius: 5px 5px 5px 5px;
  -moz-border-radius: 5px 5px 5px 5px;
  -webkit-border-radius: 5px 5px 5px 5px;
  background: #fcfcfe;
  padding: 10px;
}
.app-box:hover,
.quick-access-box:hover {
  -webkit-box-shadow: 0 3px 13px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 3px 13px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 3px 13px 0 rgba(0, 0, 0, 0.15);
}
.app-box-container {
  padding: 8px 8px 0 0;
  display: flex;
  flex-direction: column;
}

.app-box-container:hover {
  ul {
    li {
      i {
        display: block;
        cursor: pointer;
      }
    }
  }

  .close-icon {
    display: block;
    cursor: pointer;
    color: $border-color;
  }
}

.quick-access-box {
  display: flex;
  border: thin solid $border-color;
  border-radius: 5px 5px 5px 5px;
  -moz-border-radius: 5px 5px 5px 5px;
  -webkit-border-radius: 5px 5px 5px 5px;
  background: #fcfcfe;
  padding: 10px;
  p.app-name {
    color: $text-color;
    padding-bottom: 5px;
    font-size: 14px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    border-right: thin solid $border-color;
  }

  ul {
    li {
      position: relative;
      p {
        font-size: 14px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.21;
        letter-spacing: normal;
        text-align: left;
        color: $text-link-color;
        margin: 0 0 9px 0;
        padding: 0 10px 0 0;
      }
    }
  }
}

.app-details-box {
  p.app-name {
    color: $text-color;
    border-bottom: thin solid $border-color;
    padding-bottom: 5px;
    font-family: Lato;
    font-size: 14px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
  }

  ul {
    li {
      position: relative;
      p {
        font-size: 14px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.21;
        letter-spacing: normal;
        text-align: left;
        color: $text-link-color;
        margin: 0 0 9px 0;
        padding: 0 10px 0 0;
      }

      i {
        display: none;
        position: absolute;
        top: 3px;
        right: 0;
      }
    }
  }
}

.app-icon {
  padding: 0;
  img {
    width: 80%;
    height: auto;
  }
}

.quick-access-icons {
  display: flex;
}
.user-name-app {
  cursor: pointer;
  overflow-wrap: break-word;
}
.app-name-container,
.user-list-container {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
}
.app-name-quick-access {
  border-right: thin solid $border-color;
  color: $text-color;
  font-family: Lato;
  font-size: 14px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  margin: 0;
}
ul.quick-access-user-list {
  display: flex;
  flex-direction: column;
  margin: 0;
  li {
    p {
      margin: 0 1px 0 0;
      cursor: pointer;
    }
  }
}
.app-icon-quick-access {
  padding: 0;
  display: flex;
  align-items: center;
  img {
    width: 80%;
    height: auto;
  }
}
.yellow-star {
  color: $star-filled;
}

.close-icon {
  position: absolute;
  top: 4px;
  right: 4px;
  cursor: pointer;
  display: none;
}

.days-locked {
  color: #4a4a4a !important;
  cursor: not-allowed;
}

.link {
  cursor: pointer;
  color: $text-link-color;
  font-weight: 900;
}