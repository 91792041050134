// Base styles

//== Import global variables and utilities
@import 'theme';
@import '~sial-sass/core.scss';

@font-face {
  font-family: 'MFont';
  src: url('./fonts/MFont_W_Rg.eot'); /* IE9 Compat Modes */
  src: url('./fonts/MFont_W_Rg.eot?#iefix') format('embedded-opentype'),
    url('./fonts/MFont_W_Rg.woff') format('woff'),
    url('./fonts/MFont_W_Rg.ttf') format('truetype'); /* Safari, Android, iOS */
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

::selection {
  background: Highlight !important;
  color: HighlightText !important;
}

a {
  text-decoration: none !important;
}

:global {
  body {
    overflow-y: scroll;
  }
  .header {
    font-size: 32px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 48px;
  }

  .container {
    width: 1200px !important;
  }

  .divider {
    border-top: 0px;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid $gray-lighter;
    margin: 12px 0;
  }

  .ff-brand {
    font-family: $font-family-brand;
  }

  .fs-10 {
    font-size: 10px !important;
  }

  .fs-11 {
    font-size: 11px !important;
  }

  .fs-12 {
    font-size: 12px !important;
  }

  .fs-13 {
    font-size: 13px !important;
  }

  .fs-14 {
    font-size: 14px !important;
  }

  .fs-15 {
    font-size: 15px !important;
  }

  .fs-16 {
    font-size: 16px !important;
  }

  .fs-18 {
    font-size: 18px !important;
  }

  .fs-20 {
    font-size: 20px !important;
  }

  .fs-24 {
    font-size: 24px !important;
  }

  .fs-28 {
    font-size: 28px !important;
  }

  .fs-32 {
    font-size: 32px !important;
  }

  .fs-36 {
    font-size: 36px !important;
  }

  .fs-40 {
    font-size: 40px !important;
  }

  .fs-44 {
    font-size: 44px !important;
  }

  .fs-48 {
    font-size: 48px !important;
  }

  .fs-60 {
    font-size: 60px !important;
  }

  .fs-72 {
    font-size: 72px !important;
  }

  .fs-84 {
    font-size: 84px !important;
  }

  .fs-96 {
    font-size: 96px !important;
  }

  .ls-tight {
    letter-spacing: -0.5px !important;
  }

  .italic {
    font-style: italic !important;
  }

  .fw-bold {
    font-weight: bold !important;
  }

  .fw-300 {
    font-weight: 300 !important;
  }

  .fw-400 {
    font-weight: 400 !important;
  }

  .fw-700 {
    font-weight: 600 !important;
  }

  .fw-900 {
    font-weight: 900 !important;
  }

  .lh-1 {
    line-height: 1 !important;
  }

  .lh-1-4 {
    line-height: 1.4 !important;
  }

  .text-white {
    color: #fff !important;
  }

  .text-gray-dark {
    color: $gray-dark !important;
  }

  .text-gray {
    color: $gray !important;
  }

  .text-gray-medium {
    color: $gray-medium !important;
  }

  .text-gray-light {
    color: $gray-light !important;
  }

  .text-gray-lighter {
    color: $gray-lighter !important;
  }

  .text-brand-primary {
    color: $brand-primary !important;
  }

  .text-brand-secondary {
    color: $brand-secondary !important;
  }

  .text-brand-warning {
    color: $brand-warning !important;
  }

  .text-brand-danger {
    color: $brand-danger !important;
  }

  .text-brand-success {
    color: $brand-success !important;
  }

  .text-left {
    text-align: left !important;
  }

  .text-center {
    text-align: center !important;
  }

  .text-right {
    text-align: right !important;
  }

  .text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .text-uppercase {
    text-transform: uppercase !important;
  }

  .text-capitalize {
    text-transform: capitalize;
  }

  .mt-2 {
    margin-top: 2px !important;
  }

  .mt-4 {
    margin-top: 4px !important;
  }

  .mt-8 {
    margin-top: 8px !important;
  }

  .mt-12 {
    margin-top: 12px !important;
  }

  .mt-16 {
    margin-top: 16px !important;
  }

  .mt-20 {
    margin-top: 20px !important;
  }

  .mt-24 {
    margin-top: 24px !important;
  }

  .mt-28 {
    margin-top: 28px !important;
  }

  .mt-32 {
    margin-top: 32px !important;
  }

  .mt-36 {
    margin-top: 36px !important;
  }

  .mt-40 {
    margin-top: 40px !important;
  }

  .mt-44 {
    margin-top: 44px !important;
  }

  .mt-48 {
    margin-top: 48px !important;
  }

  .mt-52 {
    margin-top: 52px !important;
  }

  .mt-56 {
    margin-top: 56px !important;
  }

  .mt-60 {
    margin-top: 60px !important;
  }

  .mt-64 {
    margin-top: 64px !important;
  }

  .mt-68 {
    margin-top: 68px !important;
  }

  .mt-72 {
    margin-top: 72px !important;
  }

  .mr-2 {
    margin-right: 2px !important;
  }

  .mr-4 {
    margin-right: 4px !important;
  }

  .mr-8 {
    margin-right: 8px !important;
  }

  .mr-12 {
    margin-right: 12px !important;
  }

  .mr-16 {
    margin-right: 16px !important;
  }

  .mr-20 {
    margin-right: 20px !important;
  }

  .mr-24 {
    margin-right: 24px !important;
  }

  .mr-28 {
    margin-right: 28px !important;
  }

  .mr-32 {
    margin-right: 32px !important;
  }

  .mr-36 {
    margin-right: 36px !important;
  }

  .mr-40 {
    margin-right: 40px !important;
  }

  .mr-44 {
    margin-right: 44px !important;
  }

  .mr-48 {
    margin-right: 48px !important;
  }

  .mb-0 {
    margin-bottom: 0px !important;
  }

  .mb-2 {
    margin-bottom: 2px !important;
  }

  .mb-4 {
    margin-bottom: 4px !important;
  }

  .mb-8 {
    margin-bottom: 8px !important;
  }

  .mb-12 {
    margin-bottom: 12px !important;
  }

  .mb-16 {
    margin-bottom: 16px !important;
  }

  .mb-20 {
    margin-bottom: 20px !important;
  }

  .mb-24 {
    margin-bottom: 24px !important;
  }

  .mb-28 {
    margin-bottom: 28px !important;
  }

  .mb-32 {
    margin-bottom: 32px !important;
  }

  .mb-36 {
    margin-bottom: 36px !important;
  }

  .mb-40 {
    margin-bottom: 40px !important;
  }

  .mb-44 {
    margin-bottom: 44px !important;
  }

  .mb-48 {
    margin-bottom: 48px !important;
  }

  .mb-52 {
    margin-bottom: 52px !important;
  }

  .mb-56 {
    margin-bottom: 56px !important;
  }

  .mb-60 {
    margin-bottom: 60px !important;
  }

  .mb-64 {
    margin-bottom: 64px !important;
  }

  .mb-68 {
    margin-bottom: 68px !important;
  }

  .mb-72 {
    margin-bottom: 72px !important;
  }

  .ml-2 {
    margin-left: 2px !important;
  }

  .ml-4 {
    margin-left: 4px !important;
  }

  .ml-8 {
    margin-left: 8px !important;
  }

  .ml-12 {
    margin-left: 12px !important;
  }

  .ml-16 {
    margin-left: 16px !important;
  }

  .ml-20 {
    margin-left: 20px !important;
  }

  .ml-24 {
    margin-left: 24px !important;
  }

  .ml-28 {
    margin-left: 28px !important;
  }

  .ml-32 {
    margin-left: 32px !important;
  }

  .ml-36 {
    margin-left: 36px !important;
  }

  .ml-40 {
    margin-left: 40px !important;
  }

  .ml-44 {
    margin-left: 44px !important;
  }

  .ml-48 {
    margin-left: 36px !important;
  }

  .pt-0 {
    padding-top: 0px !important;
  }

  .pt-2 {
    padding-top: 2px !important;
  }

  .pt-4 {
    padding-top: 4px !important;
  }

  .pt-8 {
    padding-top: 8px !important;
  }

  .pt-12 {
    padding-top: 12px !important;
  }

  .pt-16 {
    padding-top: 16px !important;
  }

  .pt-20 {
    padding-top: 20px !important;
  }

  .pt-24 {
    padding-top: 24px !important;
  }

  .pt-28 {
    padding-top: 28px !important;
  }

  .pt-32 {
    padding-top: 32px !important;
  }

  .pt-36 {
    padding-top: 36px !important;
  }

  .pt-40 {
    padding-top: 40px !important;
  }

  .pt-44 {
    padding-top: 44px !important;
  }

  .pt-48 {
    padding-top: 48px !important;
  }

  .pb-0 {
    padding-bottom: 0px !important;
  }

  .pb-2 {
    padding-bottom: 2px !important;
  }

  .pb-4 {
    padding-bottom: 4px !important;
  }

  .pb-8 {
    padding-bottom: 8px !important;
  }

  .pb-12 {
    padding-bottom: 12px !important;
  }

  .pb-16 {
    padding-bottom: 16px !important;
  }

  .pb-20 {
    padding-bottom: 20px !important;
  }

  .pb-24 {
    padding-bottom: 24px !important;
  }

  .pb-28 {
    padding-bottom: 28px !important;
  }

  .pb-32 {
    padding-bottom: 32px !important;
  }

  .pb-36 {
    padding-bottom: 36px !important;
  }

  .pb-40 {
    padding-bottom: 40px !important;
  }

  .pb-44 {
    padding-bottom: 44px !important;
  }

  .pb-48 {
    padding-bottom: 48px !important;
  }

  .pl-0 {
    padding-left: 0px !important;
  }

  .pl-2 {
    padding-left: 2px !important;
  }

  .pl-4 {
    padding-left: 4px !important;
  }

  .pl-8 {
    padding-left: 8px !important;
  }

  .pl-12 {
    padding-left: 12px !important;
  }

  .pl-16 {
    padding-left: 16px !important;
  }

  .pl-20 {
    padding-left: 20px !important;
  }

  .pl-24 {
    padding-left: 24px !important;
  }

  .pl-28 {
    padding-left: 28px !important;
  }

  .pl-32 {
    padding-left: 32px !important;
  }

  .pl-36 {
    padding-left: 36px !important;
  }

  .pl-40 {
    padding-left: 40px !important;
  }

  .pl-44 {
    padding-left: 44px !important;
  }

  .pl-48 {
    padding-left: 48px !important;
  }

  .pr-0 {
    padding-right: 0px !important;
  }

  .pr-2 {
    padding-right: 2px !important;
  }

  .pr-4 {
    padding-right: 4px !important;
  }

  .pr-8 {
    padding-right: 8px !important;
  }

  .pr-12 {
    padding-right: 12px !important;
  }

  .pr-16 {
    padding-right: 16px !important;
  }

  .pr-20 {
    padding-right: 20px !important;
  }

  .pr-24 {
    padding-right: 24px !important;
  }

  .pr-28 {
    padding-right: 28px !important;
  }

  .pr-32 {
    padding-right: 32px !important;
  }

  .pr-36 {
    padding-right: 36px !important;
  }

  .pr-40 {
    padding-right: 40px !important;
  }

  .pr-44 {
    padding-right: 44px !important;
  }

  .pr-48 {
    padding-right: 48px !important;
  }

  .width-100 {
    width: 100% !important;
  }

  .width-90 {
    width: 90% !important;
  }

  .height-100 {
    height: 100% !important;
  }

  .height-32 {
    height: 32px !important;
  }

  .height-36 {
    height: 36px !important;
  }

  .block {
    display: block !important;
  }

  .inline-block {
    display: inline-block !important;
  }

  .flex {
    display: flex !important;
  }

  .flex-1 {
    flex: 1 !important;
  }

  .flex-wrap {
    flex-wrap: wrap !important;
  }

  .flex-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-direction-column {
    flex-direction: column;
  }

  .flex-align-end {
    align-items: flex-end;
  }

  .flex-align-center {
    align-items: center;
  }

  .flex-justify-center {
    justify-content: center;
  }

  .flex-justify-sb {
    justify-content: space-between;
  }

  .flex-justify-end {
    justify-content: flex-end;
  }

  .br-4 {
    border-radius: 4px;
  }

  .border {
    border: 1px solid $gray-lightest;
  }

  .border-top {
    border-top: 1px solid $gray-lightest;
  }

  .border-bottom {
    border-bottom: 1px solid $gray-lightest;
  }

  .border-left {
    border-left: 1px solid $gray-lightest;
  }

  .border-right {
    border-right: 1px solid $gray-lightest;
  }

  .pointer {
    cursor: pointer !important;
  }

  .bg-primary {
    background-color: $brand-primary !important;
  }

  .bg-secondary {
    background-color: $brand-secondary !important;
  }

  .bg-gray-x-lightest {
    background-color: $gray-x-lightest !important;
  }

  .bg-gray-skeleton {
    background-color: #e9e9e9 !important;
  }

  .rotate-45 {
    transform: rotate(45deg);
  }

  .link {
    color: $brand-primary !important;
    transition: all 200ms ease-out;
    cursor: pointer;
    &:hover,
    &:active {
      color: lighten($brand-primary, 10%) !important;
    }
  }

  .link-secondary {
    color: $brand-secondary !important;
    transition: all 200ms ease-out;
    cursor: pointer;
    &:hover,
    &:active {
      color: lighten($brand-secondary, 10%) !important;
    }
  }

  .list-item-card {
    border-radius: 4px;
    border: 1px solid $gray-lightest;
    background-color: $gray-x-lightest;
    padding: 12px 16px;
    margin-bottom: 28px;
    &:last-of-type {
      margin-bottom: 0;
    }
    .property {
      padding-right: 16px;
    }
    .label {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .value {
      color: $gray-dark;
      font-size: 13px;
      font-weight: 700;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .inline-field {
    display: flex;
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }

    .inline-label {
      width: 124px;
      font-size: 14px;
      text-align: right;
      padding-top: 9px;
      margin-right: 20px;
    }

    .inline-input {
      width: 400px;
    }
  }

  //Antd Overrides

  .ant-input {
    font-size: 13px;
  }

  .ant-form-item-label {
    margin-bottom: 4px;
  }

  .ant-input {
    &:focus {
      border: 1px solid $brand-warning !important;
    }
  }

  .ant-select .ant-select-selection__rendered {
    margin-left: 12px;
    margin-right: 12px;
  }
  .ant-select-arrow {
    font-size: 10px !important;
    margin-top: -5px !important;
    font-weight: bold !important;
    color: $brand-primary !important;
  }
  .ant-select .ant-select-selection {
    background-color: #f8f8fc;
    border: 1px solid #f8f8fc;
    font-size: 13px;

    &:hover,
    &:focus {
      border: 1px solid $brand-warning;
    }
  }

  .ant-tooltip {
    font-size: 13px !important;
  }

  .ant-tooltip-content .ant-tooltip-inner {
    padding: 6px 12px;
  }

  .ant-select-dropdown .ant-select-dropdown-menu {
    font-size: 13px;
  }

  // .ant-select-dropdown-menu .ant-select-dropdown-menu-item {
  //   padding: 8px 14px;
  // }
  .ant-calendar-range-picker-separator {
    vertical-align: inherit !important;
  }

  .ant-btn-secondary.ant-btn-background-ghost {
    color: $brand-secondary !important;
    &:hover,
    &:focus {
      border-color: lighten($brand-secondary, 12%) !important;
      color: lighten($brand-secondary, 12%) !important;
    }
  }

  .ant-message-notice .ant-message-notice-content {
    font-size: 14px !important;
    font-weight: 700;
    padding: 0;
    background-color: #fff;
    color: #fff;
  }

  .ant-message-custom-content {
    padding: 8px 20px;
    background-color: $brand-primary;
    border-radius: 2px;

    &.ant-message-success {
      background-color: $brand-secondary;
    }
    &.ant-message-error {
      background-color: $brand-danger;
    }
  }

  .ant-message-custom-content > i:first-child {
    display: none !important;
  }

  .ant-modal-header .ant-modal-title {
    font-size: 16px;
    font-weight: 700;
  }

  .ant-modal-content .ant-modal-footer {
    padding: 16px 24px;
  }
  .ant-btn.ant-btn-secondary {
    background-color: $brand-secondary;
    border-color: $brand-secondary;
    color: #ffffff;

    &:hover,
    &:focus {
      background-color: lighten($brand-secondary, 10%);
      border-color: lighten($brand-secondary, 10%);
      color: #ffffff;
    }
    &:active {
      background-color: darken($brand-secondary, 2%);
      border-color: darken($brand-secondary, 2%);
      color: #ffffff;
    }
  }
}
