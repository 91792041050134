// Default Layout Styles
@import 'theme';

.header {
  background-color: #fff;
  display: flex;
  justify-content: center;
  height: 60px;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
}

.header__left {
  display: flex;
  justify-content: flex-start;
  flex: 1 1 50%;
  padding-left: 0.5rem;
}

.header__center {
  flex: 1 0 auto;
}

.header__right {
  display: flex;
  justify-content: flex-end;
  flex: 1 1 50%;
  padding-right: 0.5rem;
}

.header__logo {
  height: 30px;
  user-select: none;
}

.header__unmark__delete {
  padding-left: 5px;
  margin-left: 5px;
}

.header__delete {
  padding-left: 5px;
  margin-left: 5px;
  padding-right: 5px;
  margin-right: 5px;
}

.header__export {
  padding-left: 5px;
  margin-left: 5px;
  padding-right: 5px;
  margin-right: 5px;
  color: #503291;
}

.header__logout__icon {
  padding-right: 5px;
  margin-right: 5px;
}

.users-filter-buttons {
  text-align: right;
  padding: 0px;
}

// Header Icon Styles
.header__icon {
  font-size: 22px;
  color: $gray-medium;
  transition: color 200ms linear;
  user-select: none;

  &:hover,
  &:focus {
    color: $gray-dark;
  }

  &.disabled {
    color: $gray-light;
  }
}

.details-ico{
  padding-right: 5px;
  margin-right: 5px;
  margin-left: 5px;
}
