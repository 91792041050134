// Default Layout Styles
@import 'theme';

.default-layout__header {
  font-family: $font-family-primary;
  position: fixed;
  width: 100%;
  z-index: 1000;
  border-bottom: 1px solid $gray-lighter;
  background-color: #fff;
  height: 60px;
}

.default-layout__content {
  padding-top: 60px;
  height: 100vh;
  margin-bottom: 72px;
}
