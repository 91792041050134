.placeholder-item {
  background-image: linear-gradient(to right, #f1ecec, #ffffff);
  background-size: 150%;
  background-position: left;
  animation: animate-background linear 2s infinite;
}
@keyframes animate-background {
  from {
    background-position: 0px 0px;
  }
  to {
    background-position: 100% 100%;
  }
}




