// Users Table Styles
@import 'theme';
.third-party-form-wrapper {
  display: flex;
  justify-content: center;

  .third-party-content {
    display: flex;
    justify-content: space-between;
    gap: 5px;

    .third-party-date-range {
      display: flex;
      gap: 2px;
    }

    .third-party-download button {
      color: #2dbecd;
      border: 1px solid #2dbecd;
    }
  }

  .third-party-form-field {
    display: flex;
    flex-direction: column;
    padding: 15px;
    border: 1px solid #dcdcdc;
    border-radius: 5px;
    width: 50%;
    gap: 20px;
    margin: 8px;

    .third-party-lable {
      font-size: 16px;
      font-weight: 700;
      color: #503291;
    }
  }
}
