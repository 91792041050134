//== Import global variables and utilities
@import 'theme';

//== Component Specific Styles

.alert-container {
  padding: 30px 120px;
  margin: 0;
  display: flex;
  justify-content: center;
  .ant-alert-info-custom {
    border: 1px solid #f7f7f7;
    background-color: #f7f7f7;
  }
}
